import {AppEvent} from "@shared/domain/events/AppEvent";

/**
 * A listener destroyer removes a listener from an event bus
 */
export type ListenerDestroyer = () => void;

/**
 * A listener is a function that gets an event every time it is emitted to process it
 */
export type Listener = (e: AppEvent<any>) => Promise<void> | void;

export abstract class EventBus {
  abstract emit(...events: AppEvent<any>[]): Promise<void>;

  abstract listen(name: string, listener: Listener): ListenerDestroyer;
}
