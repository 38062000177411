import {Routes} from '@angular/router';

export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.routes'),
  },
  // TODO add subscription guard
  {
    path: 'guest',
    loadChildren: () => import('./modules/guest/guest.routes'),
  },
  {
    path: 'host',
    loadChildren: () => import('./modules/host/host.routes'),
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "guest",
  }
];
