import {APP_INITIALIZER, ApplicationConfig, isDevMode, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideHttpClient, withFetch, withInterceptors} from "@angular/common/http";
import {accessTokenInterceptor} from '@core/interceptors/access-token/access-token.interceptor';
import {provideTransloco} from '@jsverse/transloco';
import {TranslocoHttpLoader} from "./transloco-loader";
import {EventBus} from "@shared/domain/events/EventBus";
import {InMemoryEventBusService} from "@shared/infrastructure/in-memory-event-bus.service";
import {ReloadSessionService} from "@core/core-services/reload-session.service";
import {provideEnvironmentNgxMask} from "ngx-mask";
import {languages} from "@shared/infrastructure/services/language.service";

const langCodes = languages.map(l => l.code);

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: EventBus,
      useClass: InMemoryEventBusService,
    },
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptors([accessTokenInterceptor])),
    provideTransloco({
      config: {
        availableLangs: langCodes,
        defaultLang: localStorage.getItem('defaultLang') || langCodes[0],
        fallbackLang: langCodes,
        missingHandler: {
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true
        },
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: (reloadSession: ReloadSessionService) => () => reloadSession.run(),
      deps: [ReloadSessionService],
      multi: true
    },
    provideEnvironmentNgxMask({
      validation: false,
    })
  ]
};
