import {Injectable} from '@angular/core';
import {EventBus, Listener, ListenerDestroyer} from "@shared/domain/events/EventBus";
import {AppEvent} from "@shared/domain/events/AppEvent";

@Injectable({
  providedIn: 'root'
})
export class InMemoryEventBusService implements EventBus {

  /**
   * Listeners is the responsible to save the listeners with their ids
   * @private
   */
  private _listeners: Map<string, Listener> = new Map();
  /**
   * Events is the responsible to save the listeners ids for each event.
   * @private
   */
  private _events: Map<string, Set<string>> = new Map();

  constructor() {
  }

  async emit(...events: AppEvent<any>[]): Promise<void> {
    for (const event of events) {
      const name = event.name;
      const listenerIds = this._events.get(name);
      if (!listenerIds) {
        continue;
      }

      for (const id of listenerIds) {
        const listener = this._listeners.get(id);
        if (!listener) {
          continue;
        }

        const response = listener(event)
        if (response instanceof Promise) {
          response.catch(err => {
            console.error(err);
          });
        }
      }
    }
  }

  listen(name: string, listener: Listener): ListenerDestroyer {
    const uuid = this.addListener(name, listener);

    return () => {
      this.removeListener(name, uuid);
    }
  }

  private addListener(name: string, listener: Listener): string {
    const uuid = crypto.randomUUID();
    this._listeners.set(uuid, listener);

    // Adding references to the listeners
    const listeners = this._events.get(name) || new Set<string>();
    listeners.add(uuid);
    this._events.set(name, listeners);

    return uuid;
  }

  private removeListener(name: string, id: string) {
    this._listeners.delete(id);
    // Removing listener mapping
    const listenerSet = this._events.get(name) || new Set<string>();
    listenerSet.delete(id);
    if (listenerSet.size === 0) {
      this._events.delete(name);
    } else {
      this._events.set(name, listenerSet);
    }
  }
}
