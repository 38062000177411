
import {Injectable} from '@angular/core';
import {AuthStore} from "@features/auth/framework/services/auth-store.service";
import {LoadProfileService} from "@features/profile/application/load-profile.service";
import {loginType} from "@features/auth/domain/login-type";
import {LogoutService} from "@features/auth/application/logout/logout.service";

@Injectable({
  providedIn: 'root',
})
export class ReloadSessionService {
  constructor(
    private authStore: AuthStore,
    private loadProfile: LoadProfileService,
    private logoutService: LogoutService
  ) {}

  async run() {
    // Getting access and refresh token
    const savedAccessToken = localStorage.getItem('accessToken') || undefined;
    const savedRefreshToken = localStorage.getItem('refreshToken') || undefined;
    const loginType = localStorage.getItem('loginType') || 'guest';

    if (savedAccessToken && savedRefreshToken) {
      this.authStore.setTokens(savedAccessToken, savedRefreshToken);
    }

    switch (loginType as loginType) {
      case 'admin':
        this.authStore.setLoginType('admin');
        break;
      case 'host':
        this.authStore.setLoginType('host');
        break;
      default:
        this.authStore.setLoginType('guest');
    }

    if (this.authStore.hasTokens()) {
      await this.loadProfile.run();
    }
  }
}
